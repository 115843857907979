import React, { Suspense } from 'react';
import LoaderScreen from "./Loader"
import { useTranslation } from 'react-i18next'
import storage from "./images/almacenaje.png"
import truck from "./images/transportecarga.png"
import { Row, Col, Container, Tab, Nav } from "react-bootstrap"
import './App.css';
import "./Services.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import SEO from './components/SEO';
import MungiaFooter from './components/MungiaFooter';
import MungiaInternalHeader from './components/MungiaInternalHeader';

function ServicesApp() {

  var test = (new URLSearchParams(window.location.search).get('id') == "" || new URLSearchParams(window.location.search).get('id') == null) ? "first" : new URLSearchParams(window.location.search).get('id');


  const { t, i18n } = useTranslation()

  return (
    <Suspense fallback={<LoaderScreen></LoaderScreen>}>
      <Container fluid className="px-0 main App_no_x_scroll">
        <SEO title="Inicio"></SEO>
        <Row className="">
          <Col>
            <MungiaInternalHeader></MungiaInternalHeader>
          </Col>
        </Row>
        <Row className="header_internal_bg">
          <Col>
            <Container className="mt-3 ml-4 mt-4">

              <Row className="color_white ml-4 mt-4">
                <h2 className="w-100 service_title mt-1 mt-md-5">{t('internal.services.title')}</h2>

              </Row>
            </Container>
          </Col>
        </Row>
        <div className="aqua_green_bg carousel_underbar"></div>
        <Row className="whitesmoke_bg">
          <Col>
            <Container className="mt-3 mb-3">
              <Col>
                <Tab.Container id="about-tabs" defaultActiveKey={test}>
                  <Row className="mt-4" >
                    <Col sm={12} lg className="border_right_gray">
                      <Row >
                        <Nav className="flex-column helper_negative_margin">
                          <Nav.Item className="">
                            <Nav.Link className="nav_style_2" eventKey="first"> <span className="color_yellowish bold_f">&bull;</span> &nbsp;<span className="bold_f color_text_notblack2">{t("internal.services.section1.title")}</span> </Nav.Link>
                          </Nav.Item><br></br>
                          <Nav.Item className="">
                            <Nav.Link className="nav_style_2" eventKey="second"><span className="color_yellowish bold_f">&bull;</span> &nbsp;<span className="bold_f color_text_notblack2">{t("internal.services.section2.title")}</span></Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Row>
                      <br></br>
                      <br></br>
                    </Col>
                    <Col sm={12} lg={5} className="d-flex ml-2 flex-column  text-center justify-content-center">
                      <Row >
                        <Tab.Content className="">
                          <Tab.Pane eventKey="first" className="text-left">
                            <span className="color_aqua mr-1">►</span><span className="color_text_notblack2">{t("internal.services.section1.title")}</span>

                            <div className="ml-3-md mr-2 mt-1 font_size_small color_text_notblack1 justify_text">
                              <p>{t("internal.services.section1.inner.text1")}</p>
                              <p>{t("internal.services.section1.inner.text2")}</p>
                              <p>{t("internal.services.section1.inner.text3")}</p>
                              <span>{t("internal.services.section1.inner.ul1")}</span>
                              <ul>
                                <li>{t("internal.services.section1.inner.ul1.li1")}</li>
                                <li>{t("internal.services.section1.inner.ul1.li2")}</li>
                                <li>{t("internal.services.section1.inner.ul1.li3")}</li>
                                <li>{t("internal.services.section1.inner.ul1.li4")}</li>
                              </ul>
                              <p>{t("internal.services.section1.inner.text4")}</p>
                              <br></br>
                            </div>

                          </Tab.Pane>
                          <Tab.Pane eventKey="second" className="text-left">
                            <div>
                              <span className="color_aqua mr-1">►</span><span className="color_text_notblack2">{t("internal.services.section2.inner.title")}</span>
                              <div className="ml-3-md mr-2 mt-1 font_size_small color_text_notblack1 justify_text">
                                <p>{t("internal.services.section2.inner.text1")}</p>
                                <p>{t("internal.services.section2.inner.text2")}</p>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Row>
                    </Col>
                    <Col sm={12} lg={4} className="d-flex flex-column justify-content-center text-center align-items-center">
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <img className="max_image mb-2" src={truck}></img>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <img className="max_image mb-2" src={storage}></img>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Col>
            </Container>
          </Col>
        </Row>
        <MungiaFooter></MungiaFooter>
      </Container>
    </Suspense>
  );
}

export default ServicesApp;
