import React from 'react';
import './MungiaAbout.css';
import logosup from "../images/logo800.png"
import map from "../images/mapa.png"
import { Row, Col, Tab, Nav } from "react-bootstrap"
import { withTranslation } from 'react-i18next'

const MungiaAbout = ({ t }) => {

    return (
        <Col>
            <Row >
                <h2 className="text-center w-100 service_title">{t('about.title')}</h2>

            </Row>
            <Tab.Container id="about-tabs" defaultActiveKey="first">
                <Row className="mt-4" >
                    <Col sm={12} lg className="d-flex flex-column justify-content-center text-center align-items-center">
                        <Row>
                            <Nav id="nav-tabs">
                                <Nav.Item className="border_right_gray">
                                    <Nav.Link className="nav_style" eventKey="first">{t('about.about')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="border_right_gray">
                                    <Nav.Link className="nav_style" eventKey="second">{t('about.mission')}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link className="nav_style" eventKey="third">{t('about.purpose')}</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Row>
                        <Row className="mt-4">
                            <Tab.Content className="pr-2 p-md-4 justify_text">
                                <Tab.Pane eventKey="first" className="text-left">
                                    <ul className="first_bullet">
                                        <li>{t("about.about.title")}
                                            <p className="second_bullet justify_text font-size15">{t("about.about.content1")}</p>
                                            <p className="second_bullet justify_text font-size15">{t("about.about.content2")}</p>
                                        </li>
                                    </ul>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" className="text-left">
                                    <ul className="first_bullet">
                                        <li>{t("about.mission.title")}
                                            <p className="second_bullet justify_text font-size15">{t("about.mission.content1")}</p>
                                            <p className="second_bullet justify_text font-size15">{t("about.mission.content2")}</p>
                                        </li>
                                    </ul>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third" className="text-left">
                                    <ul className="first_bullet">
                                        <li>{t("about.purpose.title")}
                                            <ul className="second_bullet">
                                                <li >
                                                    <span className="second_bullet_title">{t("about.purpose.bullet1.title")}</span><br></br>
                                                    <p className="justify_text font-size15">{t("about.purpose.bullet1.content")}</p>
                                                </li>
                                                <li >
                                                    <span className="second_bullet_title">{t("about.purpose.bullet2.title")}</span><br></br>
                                                    <p className="justify_text font-size15">{t("about.purpose.bullet2.content")}</p>
                                                </li>
                                                <li >
                                                    <span className="second_bullet_title">{t("about.purpose.bullet3.title")}</span><br></br>
                                                    <p className="justify_text font-size15">{t("about.purpose.bullet3.content")}</p>
                                                </li>
                                                <li >
                                                    <span className="second_bullet_title">{t("about.purpose.bullet4.title")}</span><br></br>
                                                    <p className="justify_text font-size15">{t("about.purpose.bullet4.content")}</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </Tab.Pane>
                            </Tab.Content>
                        </Row>
                    </Col>
                    <Col sm={12} lg className="d-flex flex-column justify-content-center text-center align-items-center">
                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <img className="image_65" src={logosup}></img>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <img className="max_image" src={map}></img>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <img className="max_image" src={map}></img>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Col>
    )
}

export default withTranslation()(MungiaAbout)