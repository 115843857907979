
import React from "react"
import Helmet from "react-helmet"

const SEO = ({title}) =>{
    return (

    <Helmet
    htmlAttributes={{
      "lang":"es",
    }}
    title={title}
    titleTemplate={`%s | C2 ENLACES TERRESTRES, S.A. DE C.V.`}
    meta={[
      {
        name: `description`,
        content: "C2 ENLACES es un proveedor de transporte terrestre de carga con sede en Manzanillo, MX.",
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: "C2 ENLACES es un proveedor de transporte terrestre de carga con sede en Manzanillo, MX.",
      },
      {
        property: `og:type`,
        content: `website`,
      },
    ]
}
  />
    )
}

export default SEO