import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import LoaderScreen from "./Loader"
import * as serviceWorker from './serviceWorker';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import "./i18n.js"
import ServicesApp from './Services';
import ScrollToTop from './components/ScrollToTop';

/*
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'es',
    backend: {
      loadPath: 'munguia/assets/i18n/{{ns}}/{{lng}}.json'
    },
    fallbackLng: 'es',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
    react: {
      wait: true,
      useSuspense:false
    }
  })

*/


ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<LoaderScreen></LoaderScreen>}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path="/munguia/services">
              <ServicesApp />
            </Route>
            <Route path="/services">
              <ServicesApp />
            </Route>
            <Route path="/">
              <App />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
