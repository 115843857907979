import React from 'react';
import './MungiaFooter.css';
import logo from "../images/logoffoter.png"
import insta from "../images/icoinsta.png"
import face from "../images/icoface.png"
import logo2 from "../images/logosup.png"
import { Container, Row, Col, Modal, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next'

const MungiaFooter = ({ t }) => {
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="aqua_green_bg carousel_underbar"></div>
            <Row className="footer_bg">
                <Col className="black_overlay">
                    <Container className="mt-3 mb-3 color_white">
                        <Row>
                            <Col className="d-none d-md-block"><img height="66" onClick={() => { window.scrollTo(0, 0); }} className="max_image" src={logo}></img>&nbsp;<span className="d-none ">{t('footer.company.name')}</span></Col>
                            <Col className="d-md-none"><img height="52" onClick={() => { window.scrollTo(0, 0); }} className="max_image" src={logo}></img>&nbsp;<span className="d-none ">{t('footer.company.name')}</span></Col>
                            <Col xs className="paddingt20 text-center" md={3} lg={6}> <span onClick={handleShow}>{t('footer.privacy')}</span></Col>
                            <Col className="paddingt20 text-center">
                                <img height="36" className="max_image" src={face}></img>
                                <img height="36" className="max_image" src={insta}></img>
                            </Col>

                        </Row>
                    </Container>
                </Col>
            </Row>
            <Modal
                show={show}
                size="lg"
                onHide={handleClose}>
                {/* <Modal.Header closeButton>
                    <Modal.Title>AVISO DE PRIVACIDAD C2 ENLACES TERRESTRES SA DE CV</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className="text-justify p-4 p-5-md">
                <img height="100" className="max_image" src={logo2}></img>
                <br></br>
                <br></br>
                <h4>AVISO DE PRIVACIDAD C2 ENLACES TERRESTRES SA DE CV</h4>
                    <p>
                        De acuerdo a lo previsto en la "LEY FEDERAL de Protección de Datos Personales", C2 Enlaces Terrestres, SA de CV, declara ser una empresa legalmente constituida de conformidad con las leyes mexicanas, con domicilio ubicado en, Blvd. Miguel de la Madrid No. 801, local 6, Playa Azul, Manzanillo Colima 28218, así como manifestar ser la responsable del tratamiento de sus datos personales.</p><p>

                        Oficina de privacidad ubicada en: Mismo domicilio.</p><p>

                        Teléfonos de la oficina de privacidad: (314) 333 80 46 // (314) 333 10 20</p><p>

                        Correo electrónico: protecciondedatos@c2enlaces.com</p><p>

                        FINALIDADES PRIMARIAS.- Su información personal será utilizada para proveer los servicios y productos que ha solicitado, informarle sobre cambios en los mismos y evaluar la calidad del servicio que le brindamos. Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales: Nombre completo, Correo Electrónico, Teléfono, Domicilio Fiscal y RFC.</p>
                        <p className="font-weight-bold">Transferencia de datos personales</p><p>
                        C2 Enlaces Terrestres, SA de CV, no realiza transferencia de sus datos personales a terceros.</p>
                        <p className="font-weight-bold">Uso de Cookies y Web Beacons</p><p>
                        Le informamos que no recabamos datos personales a través del uso de Cookies o Web Beacons, y otras tecnologías para obtener información personal de usted, como pudiera ser la siguiente:</p><p>
                        • Su tipo de navegador y sistema operativo.</p><p>
                        • Las páginas de Internet que visita.</p><p>
                        • Los vínculos que sigue.</p><p>
                        • La dirección IP.</p><p>
                        • El sitio que visitó antes de entrar al nuestro.</p><p>

                        Usted tiene derecho de acceder, rectificar y cancelar sus datos personales, así como de oponerse al tratamiento de los mismos o revocar el consentimiento que para tal fin nos haya otorgado, a través de los procedimientos que hemos implementado. Para conocer dichos procedimientos, los requisitos y plazos, se puede poner en contacto con nuestro departamento de datos personales en protecciondedatos@c2enlaces.com Tel. (314) 333 80 46 // (314) 333 10 20 o visitar nuestra página de Internet www.c2enlaces.com. en el apartado contacto.</p><p>

                        Si usted desea dejar de recibir mensajes promocionales de nuestra parte puede solicitarlo a través del correo electrónico: protecciondedatos@c2enlaces.com o al teléfono (314) 333 80 46 // (314) 333 10 20.</p><p>

                        Cualquier modificación a este aviso de privacidad podrá consultarla en www.c2enlaces.com.
                    </p>
                    
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button className="info_button" onClick={handleClose}>
                        {t('footer.modal.close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default withTranslation()(MungiaFooter)