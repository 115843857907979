import React from 'react';
import {Link} from "react-router-dom";
import './MungiaServices.css';
import icon1 from "../images/mexico.png"
import icon2 from "../images/camioncito.png"
import { Container, Row, Col, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next'

const MungiaServices = ({ t }) => {

    return (
        <Col>
            <Row >
                <h2 className="text-center w-100 service_title">{t('services.title')}</h2>

            </Row>
            <Row >
                <h6 className="text-center w-100 service_subtitle">{t('services.subtitle')}</h6>

            </Row>
            <Row className="mt-4">
                <Container>
                    <Row>
                        <Col xs={12} lg className="d-flex pr-5 pl-5 flex-column justify-content-center text-center align-items-center border_right_gray">

                            <img className="m-4" height="60px" src={icon2}></img>
                            <h3 className="title_services">{t("services.section1.title")}</h3>
                            <p className="content_services font-size15">{t("services.section1.text")}</p>
                            
                            <Link to="./services?id=first" ><Button className="info_button">+ {t("services.information")}</Button></Link>
                            
                        </Col>
                        <Col className="d-flex pr-5 pl-5 flex-column justify-content-center text-center align-items-center">

                            <img className="m-4" height="60px" src={icon1}></img>
                            <h3 className="title_services">{t("services.section2.title")}</h3>
                            <p className="content_services font-size15">{t("services.section2.text")}</p>
                            <Link to="./services?id=second" ><Button className="info_button">+ {t("services.information")}</Button></Link>
                        </Col>
                    </Row>
                </Container>
            </Row>

        </Col>
    )
}

export default withTranslation()(MungiaServices)