import React from 'react';
import './MungiaCarousel.css';
import carousel1 from "../images/ban1.jpg"
import carouselm1 from "../images/mobileban1.jpg"
import carouselm2 from "../images/mobileban2.jpg"
import carouselm3 from "../images/mobileban3.jpg"
import carouselm4 from "../images/mobileban4.jpg"
import carouselm1en from "../images/ing1.jpg"
import carouselm2en from "../images/ing2.jpg"
import carouselm3en from "../images/ing3.jpg"
import carouselm4en from "../images/ing4.jpg"
import truck2 from "../images/ban2.jpg"
import truck3 from "../images/b3.jpg"
import truck4 from "../images/b4.jpg"
import { Carousel } from "react-bootstrap"
import { withTranslation } from 'react-i18next'
import i18next from 'i18next';

const MungiaCarousel = ({t}) => {
    
    return (
        <div>
            <Carousel>
                <Carousel.Item>
                    <img className="image_carousel d-none d-lg-block" src={carousel1} alt="carousel" />
                    <img className="image_carousel d-lg-none" src={i18next.language =="es" ? carouselm1: carouselm1en} alt="carousel" />
                    <Carousel.Caption className="caption_bg1 d-none d-lg-block">
                        <h3 className="text_carousel">{t('carousel.1.text')}</h3>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item >
                    <img className="image_carousel d-none d-lg-block" src={truck2} alt="carousel" />
                    <img className="image_carousel d-lg-none" src={i18next.language =="es" ? carouselm2: carouselm2en} alt="carousel" />
                    <Carousel.Caption className="caption_bg2 d-none d-lg-block">
                        <h3 className="text_carousel2">{t('carousel.2.text')}</h3>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item >
                    <img className="image_carousel d-none d-lg-block" src={truck3} alt="carousel" />
                    <img className="image_carousel d-lg-none" src={i18next.language =="es" ? carouselm3: carouselm3en} alt="carousel" />
                    <Carousel.Caption className="caption_bg3 d-none d-lg-block">
                        <h3 className="text_carousel3">{t('carousel.2.text')}</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item >
                    <img className="image_carousel d-none d-lg-block" src={truck4} alt="carousel" />
                    <img className="image_carousel d-lg-none" src={i18next.language =="es" ? carouselm4: carouselm4en} alt="carousel" />
                    <Carousel.Caption className="caption_bg4 d-none d-lg-block">
                        <h3 className="text_carousel2">{t('carousel.2.text')}</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                {/* <Carousel.Item>
                    <img className="image_carousel" src={carousel1} alt="carousel" />
                    <Carousel.Caption className="caption_bg">
                        <h3 className="text_carousel">{t('carousel.1.text')}</h3>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item >
                    <img className="image_carousel" src={carousel1} alt="carousel" />
                    <Carousel.Caption className="caption_bg">
                        <h3 className="text_carousel">{t('carousel.1.text')}</h3>
                    </Carousel.Caption>
                </Carousel.Item> */}
            </Carousel>
        </div>
    )
}

export default withTranslation()(MungiaCarousel)