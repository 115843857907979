import React from 'react';
import './MungiaContact.css';
//import phone from "../images/icocel.png"
//import mail from "../images/mail.png"
import phone from "../images/telefono.svg"
import mail from "../images/email.svg"
import edecan from "../images/edecan.png"
import { Form, Row, Col, Button } from "react-bootstrap"
import { withTranslation } from 'react-i18next'

import ReCAPTCHA from "react-google-recaptcha";

const MungiaContact = ({ t }) => {

    const siteKey = "6LdBtbMZAAAAAEnhTYMtOpkJAQikG32FWxGCDqyW"
    const [captcha, setCaptcha] = React.useState(false)
    const [validated, setValidated] = React.useState(false);

    const onChange = (value) => {

        console.log("Captcha value:", value);
        setCaptcha(true)

    }

    const abortController = new AbortController
    const [values, setValues] = React.useState({
        name: '',
        mail: '',
        message: '',
        phone: '',
        company: ''
    });

    const [sending, setSending] = React.useState(false)

    const handleChange = prop => event => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const sendMessage = (event) => {

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (Boolean(values.name.trim()) &&
            Boolean(values.mail.trim()) &&
            Boolean(values.message.trim()) &&
            Boolean(values.phone.trim()) &&
            Boolean(values.company.trim()) &&
            form.checkValidity() === true
        ) {

            setSending(true)

            fetch("https://glaci.us/services/rest/munguia/Contact", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            })
                .then(response => {
                    if (response.status !== 200) {
                        // something went wrong
                        console.log('Looks like there was a problem. Status Code: ' +
                            response.status);


                        alert("Ocurrió un error al enviar la información al servidor.")
                        return;
                    } else {
                        
                        alert("Su mensaje se envió exitosamente. ¡Gracias!");
                        setSending(false)
                        setValues({
                            name: '',
                            mail: '',
                            message: '',
                            phone: '',
                            company: ''
                        })


                    }
                })

        }
        else {
            setValidated(true)
        }
        return () => {
            abortController.abort()
        }

    }





    return (
        <Col>
            <Row className="mt-3 mb-3">
                <h2 className="text-center w-100 service_title">{t('contact.title')}</h2>

            </Row>
            <Row >
                <Col xs={12} lg={4} className="pr-4 ">
                    <Row className="pb-2 flex-column">
                        <h4 className="mb-4 office_corporate">{t('contact.office.corporate.title')}</h4>
                        <h6 className="mb-1 office_title">{t('contact.office1.title')}</h6>
                        <span className="office_address mb-1">{t('contact.office1.address')}</span>

                        <div className="office_contact mt-2" onClick={() => { window.open("tel:" + t('contact.office1.phone')) }}>
                            <img width="18" height="18" className="mr-2" src={phone}></img><span>&nbsp;{t('contact.office1.phone')}</span>
                        </div>
                        <div className="office_contact mt-2" onClick={() => { window.open("tel:" + t('contact.office1.phone')) }}>
                            <img width="18" height="18" className="mr-2" src={phone}></img><span>&nbsp;{t('contact.office1.phone2')}</span>
                        </div>
                        <div className="office_contact mt-1" onClick={() => { window.open("mailto:" + t('contact.office1.email')) }}>
                            <img width="18" height="18" className="mr-2" src={mail}></img><span>&nbsp;{t('contact.office1.email')}</span>
                        </div>

                    </Row>
                    <Row className="pb-3 pt-3  flex-column">
                        <h4 className="office_corporate">{t('contact.office.title')}</h4>
                        <div className="mt-2">
                            <ul className="contact_bullet">
                                <li>
                                    <h6 className="mb-1 office_title">{t('contact.office2.title')}</h6>
                                    <span className="office_address mb-1">{t('contact.office2.address')}</span>
                                    <br></br>
                                    <div className="office_contact mt-3" onClick={() => { window.open("tel:" + t('contact.office2.phone')) }}>
                                        <img width="18" height="18" className="mr-2" src={phone}></img><span>&nbsp;{t('contact.office2.phone')}</span>
                                    </div>
                                    <div className="office_contact mt-1" onClick={() => { window.open("mailto:" + t('contact.office2.email')) }}>
                                        <img width="18" height="18" className="mr-2" src={mail}></img><span>&nbsp;{t('contact.office2.email')}</span>
                                    </div>

                                </li>
                            </ul>


                        </div>
                        <div>
                            <ul className="contact_bullet">
                                <li>
                                    <h6 className="mb-1 office_title">{t('contact.office3.title')}</h6>
                                    <span className="office_address mb-1 ">{t('contact.office3.address')}</span>
                                    <br></br>
                                    <div className="office_contact mt-3" onClick={() => { window.open("tel:" + t('contact.office3.phone')) }}>
                                        <img width="18" height="18" className="mr-2" src={phone}></img><span>&nbsp;{t('contact.office3.phone')}</span>
                                    </div>
                                    <div className="office_contact mt-1" onClick={() => { window.open("mailto:" + t('contact.office3.email')) }}>
                                        <img width="18" height="18" className="mr-2" src={mail}></img><span>&nbsp;{t('contact.office3.email')}</span>
                                    </div>

                                </li>
                            </ul>


                        </div>

                    </Row>

                </Col>
                <Col xs={12} lg={5}>
                    <h4>{t("contact.contact.title")}</h4>
                    <div className="font-size15">
                        {t("contact.contact.text")}
                    </div>
                    <br></br>
                    <Form className="mt-2" validated={validated} onSubmit={sendMessage}>
                        <Form.Group controlId="formBasicName">
                            <Form.Control value={values.name} className="mungia_input" onChange={handleChange('name')} type="text" placeholder={t("contact.form.name")} required />
                        </Form.Group>
                        <Form.Group controlId="formBasicCompany">
                            <Form.Control value={values.company} className="mungia_input" onChange={handleChange('company')} type="text" placeholder={t("contact.form.company")} required />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control value={values.mail} className="mungia_input" onChange={handleChange('mail')} type="email" placeholder={t("contact.form.email")} required />
                        </Form.Group>
                        <Form.Group controlId="formBasicPhone">
                            <Form.Control value={values.phone} className="mungia_input" onChange={handleChange('phone')} type="text" placeholder={t("contact.form.phone")} required />
                        </Form.Group>

                        <Form.Group controlId="formBasicMessage">

                            <Form.Control value={values.message} className="mungia_input" onChange={handleChange('message')} type="text" as="textarea" rows="7" placeholder={t("contact.form.message")} required />
                        </Form.Group>

                        {(captcha && !sending) &&
                            <Button type="submit" variant="warning" className="w-100" >
                                {t("contact.form.send")}
                            </Button>
                        }
                        {(captcha && sending) &&
                            <div className="d-flex justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">{t("contact.form.loading")}</span>
                                </div>
                            </div>
                        }


                        {!captcha &&
                            <ReCAPTCHA
                                sitekey={siteKey}
                                onChange={onChange}
                            />
                        }

                    </Form>
                </Col>
                <Col className="d-none d-lg-block position_relative"><img className="max_image edecan_photo" src={edecan}></img></Col>
            </Row>

        </Col>
    )
}

export default withTranslation()(MungiaContact)