
import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css';

const LoaderScreen = () => {
    console.log("woof")
    return (

        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    )
}

export default LoaderScreen