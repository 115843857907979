import React, { Suspense } from 'react';
import LoaderScreen from "./Loader"
import icon1 from "./images/sustenta.png"
import logosup from "./images/logosup.png"
import { useTranslation } from 'react-i18next'
import { Row, Col, Container } from "react-bootstrap"
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SEO from './components/SEO';
import MungiaHeader from './components/MungiaHeader';
import MungiaAbout from './components/MungiaAbout';
import MungiaServices from './components/MungiaServices';
import MungiaFooter from './components/MungiaFooter';
import MungiaContact from './components/MungiaContact';

function App() {

  const { t, i18n } = useTranslation()

  return (

    <Suspense fallback={<LoaderScreen></LoaderScreen>}>
      <Container fluid className="px-0 main App_no_x_scroll">
        <SEO title="Inicio"></SEO>
        <Row className="">
          <Col>
            <MungiaHeader></MungiaHeader>
          </Col>
        </Row>
        <div className="aqua_green_bg carousel_underbar"></div>
        <Row id="services" className="mt-2 mb-2">
          <Col>
            <Container className="mt-3 mb-3">
              <MungiaServices></MungiaServices>

            </Container>
          </Col>
        </Row>
        <Row id="about" className="whitesmoke_bg">
          <Col className="mt-2 mb-2">
            <Container className="mt-3 mb-3">
              <MungiaAbout></MungiaAbout>
            </Container>
          </Col>
        </Row>
        <Row id="sustainability" className="black_bg color_white">
          <Col className="mt-3 mb-3">
            <Container className="mt-3 mb-3">
              <Col>
                <Row className="mb-3">
                  <h2 className="text-center w-100 service_title">{t('sustainability.title')}</h2>

                </Row>
                <Row>
                  <Col xs={12} md className="d-flex flex-column justify-content-center justify_text padding0">
                    <p className="font-size15">{t("sustainability.text1")}</p>
                    <p className="font-size15">{t("sustainability.text2")}</p>
                  </Col>
                  <Col >
                    <img className="max_image" src={icon1}></img>

                  </Col>
                </Row>
              </Col>

            </Container>
          </Col>
        </Row>
        <Row className="" id="contact">
          <Col>
            <Container className="mt-3 mb-3">
              <MungiaContact></MungiaContact>
            </Container>
          </Col>
        </Row>
        <MungiaFooter></MungiaFooter>
      </Container>
    </Suspense>
  );
}

export default App;
