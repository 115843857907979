import React from 'react';
import './MungiaHeader.css';
import logosup from "../images/logosup.png"
import logo from "../images/logoffoter.png"
import mex from "../images/1.png"
import usa from "../images/2.png"
import { HashLink as Link } from 'react-router-hash-link';
import { withTranslation, useTranslation } from 'react-i18next'
import MungiaCarousel from './MungiaCarousel';
import { Navbar, Nav } from 'react-bootstrap';

const MungiaHeader = ({ t }) => {
    const { i18n } = useTranslation()

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
    }
    const setSpanish = () => {
        changeLanguage("es")
    }
    const setEnglish = () => {
        changeLanguage("en")
    }

    return (
        <div>
            <Navbar className="d-lg-none nav_bar_bg" expand="lg" bg="dark" variant="dark">
                <Navbar.Brand className="high_index">
                    <Link to="./">
                        <img
                            src={logo}
                            width="52"
                            height="50"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle className="high_index" aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto text_color_white text-center">
                        <Link className="mt-1" to="./">{t("menu.mobile.home")}</Link>
                        <Link to="./#services">{t("menu.mobile.services")}</Link>
                        <Link to="./#about">{t("menu.mobile.about")}</Link>
                        <Link to="./#sustainability">{t("menu.mobile.sustainability")}</Link>
                        <Link to="./#contact">{t("menu.mobile.contact")}</Link>
                        <div className="d-flex flex-row justify-content-around pt-3 ml-1">
                            <div onClick={setSpanish} className="p-1">
                                <img width="30px" src={mex}></img>
                            </div>
                        </div>
                        <div className="d-flex flex-row justify-content-around pt-3 ml-1">
                            <div onClick={setEnglish} className="p-1">
                                <img width="30px" src={usa}></img>
                            </div>
                        </div>
                    </Nav>
                </Navbar.Collapse>

                <div className="fake_nav_bar_bg"></div>
            </Navbar>

            <div className="desktop_overlayed_header_container d-none d-lg-block">

                <div className="desktop_fake_bg">

                </div>
                <div className="desktop_logo">
                    <Link to="./"><img className="desktop_logo_img" src={logosup}></img></Link>
                </div>
                <div className="desktop_menu_container">
                    <div className="d-flex flex-row-reverse">
                        <div className="d-flex flex-row justify-content-around pt-3 ml-1">
                            <div onClick={setSpanish} className="p-1 cursor_pointer">
                                <img width="30px" src={mex}></img>
                            </div>

                            <div onClick={setEnglish} className="p-1 cursor_pointer">
                                <img width="30px" src={usa}></img>
                            </div>

                        </div>
                        <div className="d-flex flex-column">
                            <span className="desktop_call_us">{t('callus.text')}</span>
                            <span>
                                <span className="desktop_phone cursor_pointer" onClick={()=>{window.open("tel:0180023456789")}}>+52 (314) 333 8046</span>&nbsp;|&nbsp;
                                <span className="desktop_phone cursor_pointer" onClick={()=>{window.open("tel:+52 (314) 333 10 20")}}>+52 (314) 333 1020</span>
                            </span>
                        </div>
                    </div>
                    <div className="desktop_actual_menu_container">
                        <div className={" desktop_menu_item menu-centered "}><Link to="./#contact">{t("menu.contact")}</Link></div>
                        <div className={" border_right_aqua desktop_menu_item menu-centered "}><Link to="./#sustainability">{t("menu.sustainability")}</Link></div>
                        <div className={" border_right_aqua desktop_menu_item menu-centered "}><Link to="./#about">{t("menu.about")}</Link></div>
                        <div className={" border_right_aqua desktop_menu_item menu-centered "}><Link to="./#services">{t("menu.services")}</Link></div>
                        <div className={" border_right_aqua desktop_menu_item menu-centered "}><Link to="./">{t("menu.home")}</Link></div>
                    </div>
                </div>

            </div>
            <MungiaCarousel>
            </MungiaCarousel>
        </div>
    )
}

export default withTranslation()(MungiaHeader)